const surveyProductionBackendUrl = 'https://survey.api.jointheleague.org' 
//const surveyDevelopmentBackendUrl = 'http://localhost:8080'

const surveyBackendUrl = surveyProductionBackendUrl;

class AdminDataService {

    completeSurveyOrder(pearId, lastName) {
        const surveyRequestDO = {
            pearId: pearId,
            lastName: lastName
        };
        return fetch(surveyBackendUrl + '/survey/order/complete', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(surveyRequestDO)
        })
    }

}
export default new AdminDataService()

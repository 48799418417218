import React, { Component } from 'react';
import ContentContainer from '../../components/ContentContainer';
import LeagueNameLogoContainer from '../../components/LeagueNameLogo';
import StudentLoginForm from '../../forms/StudentLoginForm';

class StudentView extends Component {

    render() {
        return <div style = {{marginTop:'5%'}}>
            <ContentContainer>
                
                <LeagueNameLogoContainer/>

                <h2 style= {{width:'100%'}} >Pear Survey Gateway</h2>

                <StudentLoginForm openSurvey = {this.props.openSurvey}/>
            
            </ContentContainer>
        </div>

    }
}

export default StudentView
import React, { Component } from 'react';

class SurveyView extends Component {

    render() {
        return <div style={{
            backgroud:'red',
            border: 'solid'
        }}>
            <iframe
                title="League Pear Survey"
                src={"https://thepearinstitute.co1.qualtrics.com/jfe/form/SV_1AmhnVtVwzTARmZ"}
                style={{
                    width:'100%',
                    height:'100vh'
                }}></iframe>
        </div>

    }
}

export default SurveyView;
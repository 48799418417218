import React, { Component } from 'react';
import LoginView from './subpages/LoginView'
import SurveyView from './subpages/SurveyView'

//import { createBrowserHistory } from 'history';

//const history = createBrowserHistory();

class WorkspaceDistributorView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: 'LoginView'
        };
    }

    render() {
        let { view } = this.state;
        const BodyComponent = () => {
            if (view === 'LoginView') {
                return <LoginView
                    openSurvey={(pearId => {
                        this.setState({
                            pearId: pearId,
                            view: 'SurveyView'
                        });
                    })}
                />
            } else if (view === 'SurveyView') {
                return <SurveyView/>
            } 
        }

        return (
            <div style={{
                position: 'absolute',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                height: '100%',
                backgroundImage: 'linear-gradient(45deg, #e3e3e3 33.33%, #d9d9d9 33.33%, #d9d9d9 50%, #e3e3e3 50%, #e3e3e3 83.33%, #d9d9d9 83.33%, #d9d9d9 100%)',
                backgroundSize: '21.21px 21.21px'
            }}>
                <BodyComponent />
            </div>
        )
    }
}

export default WorkspaceDistributorView;
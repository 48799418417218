import React, { Component } from 'react';
import { Input, Button } from 'antd';
import DataService from '../service/DataService'
import { LoginOutlined } from '@ant-design/icons';
import { errorNotification } from '../components/Notification'

const inputFieldStyle = { marginBottom: '10px', width: '20em' };

class StudentIdVerificationForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pearId: '',
            lastName: '',
            hasLoginFailed: false,
            redirect: false,
            showSuccessMessage: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
    }

    handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    loginClicked() {
    
        let { pearId, lastName } = this.state;
        pearId = pearId.trim();
        lastName = lastName.trim();
        
        DataService.completeSurveyOrder(pearId, lastName)
            .then(response => {
                console.log(response.body)
                if(response.ok){
                    //window.open("https://thepearinstitute.co1.qualtrics.com/jfe/form/SV_1AmhnVtVwzTARmZ");
                    this.props.openSurvey(pearId);
                }
                else{
                    if(response.status === 400){
                        response.json().then(response=>{
                            console.log(response)
                            errorNotification(`Oops...`, response.message);

                            });
                    }
                }
            })
            .catch(err => {
                errorNotification(`Error - (${err.httpStatus})`, `${err.message}`);
            });
            ;
    }

    render() {
        return (
            <div className="container"
                style={{ margin: '2.5em' }}>
                {/* // style={{
                //     margin: '2.5em',
                //     display: 'block',
                //     float: 'left',
                //     width: '100%',
                //     borderStyle: 'solid'
                // }}> */}

                {this.state.hasLoginFailed && <div className="alert alert-warning">Invalid Credentials</div>}
                {this.state.showSuccessMessage && <div>Login Sucessful</div>}
                <div style={{ width: '100%' }}>
                    <Input type="text"
                        name="lastName"
                        placeholder="Last Name"
                        size="large"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        style={inputFieldStyle}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <Input type="text"
                        name="pearId"
                        placeholder="Pear ID"
                        size="large"
                        value={this.state.pearId}
                        onChange={this.handleChange}
                        style={inputFieldStyle}
                    />
                </div>
                <Button
                    className="btn btn-success"
                    onClick={this.loginClicked}
                    type="primary"
                    shape="round"
                    icon={<LoginOutlined />}
                    size={'large'}
                    style={{
                        marginTop: '2.5em',
                        background: 'black',
                        borderColor: 'white'
                    }}
                >
                    
                    Let's Go
                    </Button>
            </div>
        );
    }
}

export default StudentIdVerificationForm;